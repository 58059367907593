import React from 'react'

const Blockchain = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">BlockChain</h3>
        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">Solidity</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">Ether.js</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

                {/* <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">DBMS</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div> */}

            </div>

            {/* ------------------------------------ */}

            <div className="skills__group">
                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">HardHat</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

                {/* <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">Cryptography</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div> */}

                {/* <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">Git & GitHub</h3>
                        <span className="skills__level"></span>
                    </div>
                </div> */}

            </div>
        </div>
    </div>
  )
}

export default Blockchain