import React, { useState } from 'react'
import "./qualification.css"
const Qualification = () => {
    const [toggleState, setToggleState] = useState(2)
    const toggleTab = (index) => {
        setToggleState(index);
    };
    return (
        <section className="qualification section" id="qualification">
            <h2 className="section__title">Qualification</h2>
            <span className="section__subtitle">My personal journey</span>

            <div className="qualification__container container">
                <div className="qualification__tabs">
                    <div className={toggleState === 1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}
                        onClick={() => toggleTab(1)}>
                        <i className="uil uil-graduation-cap qualification__icon"></i> Education
                    </div>

                    <div className={toggleState === 2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}
                        onClick={() => toggleTab(2)}>
                        <i className="uil uil-briefcase-alt qualification__icon"></i> Experience
                    </div>

                </div>

                <div className="qualification__sections">
                    <div className={toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Bachelor of Technology</h3>
                                <span className="qualification__subtitle">The neotia University</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>Aug,2019-May,2023
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>


                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>


                            <div>
                                <h3 className="qualification__title">Higer Secondary</h3>
                                <span className="qualification__subtitle">K.A.M.M. High School </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>2019
                                </div>
                            </div>

                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Secondary</h3>
                                <span className="qualification__subtitle">K.A.M.M. High School</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>2017
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>



                    </div>

                    {/* Experience */}
                    <div className={toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>


                            <div>
                                <h3 className="qualification__title">Software Trainee</h3>
                                <span className="qualification__subtitle">DCG Datacore Systems India pvt. ltd.</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>Aug,2023-present
                                </div>
                            </div>

                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Backend Developer Intern</h3>
                                <span className="qualification__subtitle">HomeTuitionEdu Pvt Ltd</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>Aug,2022-Oct,2022
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>


                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>


                            <div>
                                <h3 className="qualification__title">Backend Developer</h3>
                                <span className="qualification__subtitle">College project Team</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>Jan,2022-July,2022
                                </div>
                            </div>

                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Web Development Summer training</h3>
                                <span className="qualification__subtitle">Internshala</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>2021
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default Qualification