import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__container container">
            <h1 className="footer__title">Ankan</h1>

            <ul className="footer__list">
                <li>
                    <a href="#about" className="footer__link">About</a>
                </li>
                <li>
                    <a href="#skills" className="footer__link">Skills</a>
                </li>
                {/* <li>
                    <a href="#" className="footer__link"></a>
                </li> */}
            </ul>

            <div className="footer__social">
                <a href='https://leetcode.com/iamankanghosh/' className='footer__social-link' target="_blank" rel="noreferrer">
                    <i className='uil uil-arrow'></i>
                </a>
                <a href='https://github.com/iamankanghosh' className='footer__social-link' target="_blank" rel="noreferrer">
                    <i className='uil uil-github-alt'></i>
                </a>
                <a href='https://www.linkedin.com/in/iamankanghosh/' className='footer__social-link' target="_blank" rel="noreferrer">
                    <i className='uil uil-linkedin'></i>
                </a>
                <a href='https://www.instagram.com/iamankanghosh/' className='footer__social-link' target="_blank" rel="noreferrer">
                    <i className='uil uil-instagram'></i>
                </a>
            </div>
           
            <span className="footer__copy">Phone :+91 8436201492, Email : ankang790@gmail.com
            <br/>
            <br />
            Copyright &#169; Ankan Ghosh. All right reserved</span>
        </div>
    </footer>
  )
}

export default Footer