import React,{useState} from 'react' ;
import "./header.css" ;
const Header = () => {
    window.addEventListener("scroll",function() {
        const header = document.querySelector(".header");
        if(this.scrollY >=80) header.classList.add("scroll-header");
        else header.classList.remove("scroll-header")
    })


    const[Toggle, showMenu] = useState(false);
    const[activenav, setActiveNav] = useState("#home");
    return (
    <header className = "header">
        <nav className = "nav container" >
            <a href="index.html" className= "nav__logo"> <b><i className="uil uil-smile"></i>Ankan </b></a>
            <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
                <ul className = "nav__list grid" >
                    <li className = "nav__item" >
                        <a href="#home" onClick={()=>{ setActiveNav('#home')}} className={activenav === "#home " ? "nav__link active-link" :"nav__link"}>
                            <i className = " uil uil-house-user nav__icon" >Home </i>
                        </a>
                    </li>
                    <li className = "nav__item" >
                        <a href="#about" onClick={()=>{ setActiveNav('#about')}} className={activenav === "#about " ? "nav__link active-link" :"nav__link"}>
                            <i className = " uil uil-user nav__icon" >About </i>
                        </a>
                    </li>
                    <li className = "nav__item" >
                        <a href="#skills" onClick={()=>{ setActiveNav('#skills')}} className={activenav === "#skills " ? "nav__link active-link" :"nav__link"}>
                            <i className = " uil uil-file-alt nav__icon" >Skills </i>
                        </a>
                    </li>
                    <li className = "nav__item" >
                        <a href="#qualification" onClick={()=>{ setActiveNav('#qualification')}} className={activenav === "#qualification " ? "nav__link active-link" :"nav__link"}>
                            <i className = " uil uil-university nav__icon" >Qualification </i>
                        </a>
                    </li>
                    <li className = "nav__item" >
                        <a href="#contact" onClick={()=>{ setActiveNav('#contact')}} className={activenav === "#contact " ? "nav__link active-link" :"nav__link"}>
                            <i className = " uil uil-message nav__icon" >Contact </i>
                        </a>
                    </li>
                </ul>
                <i className ="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}> </i>
            </div>
            <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
                <i className = "uil uil-apps" > </i>
            </div>
        </nav>
    </header>
    )
}
export default Header;
