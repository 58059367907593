import React from 'react'

const Social = () => {
  return (
    <div className="home__social">
        <a href='https://leetcode.com/iamankanghosh/' className='home__social-icon' target="_blank">
            <i className='uil uil-arrow'></i>
        </a>
        <a href='https://github.com/iamankanghosh' className='home__social-icon' target="_blank">
            <i className='uil uil-github-alt'></i>
        </a>
        <a href='https://www.linkedin.com/in/iamankanghosh/' className='home__social-icon' target="_blank">
            <i className='uil uil-linkedin'></i>
        </a>
    </div>
  ) 
}

export default Social