import React from 'react'

const Info = () => {
  return (
    <div className="about__info grid">

      <div className="about__box">
        <i className="uil uil-game-structure"></i>
        <h3 className="about__title">Problem Solving / DSA</h3>
        <span className="about__subtitle">Solved 200+ problem</span>
      </div>

      <div className="about__box">
        <i className="uil uil-award"></i>
        <h3 className="about__title">Experience</h3>
        <span className="about__subtitle">1 internship, currently 1 full tine job</span>
      </div>

      <div className="about__box">
        <i className="uil uil-briefcase-alt"></i>
        <h3 className="about__title">Wroked On</h3>
        <span className="about__subtitle">2
          Project</span>
      </div>




    </div>
  )
}

export default Info